import React, { useState, useEffect } from 'react';
import PaymentContext from './PaymentContext';
import * as payment from './../services/payment.js';
import * as charge from './../services/charge.js';
import axios from 'axios';
import { useContext } from 'react';
import AuthContext from './AuthContext';

function PaymentProvider({ children }) {
    const [ qrCodePix, setQrCodePix ] = useState(null); 
    const [ boleto, setBoleto ] = useState(null);

    useEffect(() => {
        const qrCodePix = localStorage.getItem('app-mercado-unido-qrCodePix');
        const boleto = localStorage.getItem('app-mercado-unido-boleto');
    
        if(qrCodePix) {
            setQrCodePix(JSON.parse(qrCodePix));

            return;
        }

        if(boleto) {
            setBoleto(JSON.parse(boleto));

            return;
        }
    }, []);

    async function getBoleto(idCharge, token) {
        const response = await charge.getBoleto(idCharge, token);

        return (response.status === 200) ? response : false;
    }

    async function generateQrCodePix(
        token, description, value, id_product, id_cart, id_offer
    ) {
        const response = await payment.generateQrCodePix(
            token, description, value, id_product, id_cart, id_offer
        );

        if(response.status === 201) {
            setQrCodePix(response.data);
            localStorage.setItem('app-mercado-unido-qrCodePix', JSON.stringify(response.data));

            return response;
        }
    }

    async function generateBoleto(
        token, description, value, 
        street_shipping, number_shipping, locality_shipping, city_shipping, region_code_shipping, postal_code_shipping, 
        id_product, id_cart, id_offer
    ) {
        const response = await payment.generateBoleto(
            token, description, value, 
            street_shipping, number_shipping, locality_shipping, city_shipping, region_code_shipping, postal_code_shipping, 
            id_product, id_cart, id_offer
        );

        if(response.status === 201) {
            setBoleto(response.data);
            localStorage.setItem('app-mercado-unido-boleto', JSON.stringify(response.data));

            return response;
        }
    }

    async function payWithCreditCard(token, value, installments, number, exp_month, exp_year, security_code, holder, id_cart, id_offer, id_product) {
        const card = window.PagSeguro.encryptCard({
            publicKey: "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr+ZqgD892U9/HXsa7XqBZUayPquAfh9xx4iwUbTSUAvTlmiXFQNTp0Bvt/5vK2FhMj39qSv1zi2OuBjvW38q1E374nzx6NNBL5JosV0+SDINTlCG0cmigHuBOyWzYmjgca+mtQu4WczCaApNaSuVqgb8u7Bd9GCOL4YJotvV5+81frlSwQXralhwRzGhj/A57CGPgGKiuPT+AOGmykIGEZsSD9RKkyoKIoc0OS8CPIzdBOtTQCIwrLn2FxI83Clcg55W8gkFSOS6rWNbG5qFZWMll6yl02HtunalHmUlRUL66YeGXdMDC2PuRcmZbGO5a/2tbVppW6mfSWG3NPRpgwIDAQAB",
            holder: holder,
            number: number,
            expMonth: exp_month,
            expYear: exp_year,
            securityCode: security_code
        });
console.log(card)
        
        const response = await payment.payWithCreditCard(token, value, installments, card.encryptedCard, id_cart, id_offer, id_product);

        if(response.status === 201) {
            return response;
        }

        return false;
    }

    const numParcelas = 12
    const [ toAlterPersonalData, setToAlterPersonalData ] = useState(false);
    const [ toAlterAddressData, setToAlterAddressData ] = useState(false);

    const context = {
        generateQrCodePix,
        generateBoleto,
        payWithCreditCard,
        boleto, setBoleto, getBoleto,
        qrCodePix, setQrCodePix,
        numParcelas,
        toAlterPersonalData, setToAlterPersonalData,
        toAlterAddressData, setToAlterAddressData
    }

    return (
        <PaymentContext.Provider value={context}>
            {children}
        </PaymentContext.Provider>
    )
}

export default PaymentProvider;