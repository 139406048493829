import React, { useState } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { SearchIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { formatMoney, TimestampFormatterCreatedAt, TimestampFormatterPaymenteDate } from '../utils/format';
import MainContext from '../context/MainContext';
import logo_pagseguro from '../assets/images/logo-pagseguro.png';
import PaymentContext from '../context/PaymentContext';

export default function MyOrders() {
    const { orders, user, token } = useContext(AuthContext);
    const { deliveryFee } = useContext(MainContext);
    const { getBoleto } = useContext(PaymentContext);

    const [search, setSearch] = useState('');
    const [records, setRecords] = useState(orders);

    const handleOnChangeSearch = async (e) => {
        e.preventDefault();

        setRecords(orders.filter(orders => orders.offer.title.toLowerCase().includes(search.toLowerCase())));
    }

    const handleClickClearInputSearch = async (e) => {
        e.preventDefault();

        setRecords(orders);
        setSearch('');
    }

    const handleClickFinalizarPagamento = async (e, payType, idCharge) => {
        e.preventDefault();

        try {
            if (payType === 'BOLETO') {
                const response = await getBoleto(idCharge, token);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const navigate = useNavigate();

    return (
        <>
            <Header />
            <section className="bg-background-gray py-10">
                {/* =======@ DESKTOP @======= */}
                <article className='bg-white max-w-[862px] py-[30px] rounded-xl shadow-md mx-auto mb-[40px] px-[30px] hidden lg:block'>
                    <div className='flex flex-row items-center hover:cursor-pointer hover:opacity-80' onClick={() => navigate(-1)}>
                        <AiOutlineArrowLeft className='text-primary-blue font-bold' />
                        <p className='px-2 text-primary-blue underline'>Voltar</p>
                    </div>
                    <h3 className="text-primary-blue text-left w-full font-bold text-[35px]">Meus Pedidos {`(${records.length})`}</h3>
                    <div className="text-sm flex-auto pb-[30px] pt-[30px]">
                        <div className="w-full">
                            <label htmlFor="search" className="sr-only">
                                Procurar por um pedido
                            </label>
                            <div className="relative">
                                <input
                                    value={search}
                                    onChange={(e) => {
                                        setSearch(e.target.value);
                                    }}
                                    id="search"
                                    name="search"
                                    className="block w-full h-[40px] mx-auto pr-10 pl-3 py-1 border border-gray-300 rounded-lg leading-5 bg-white text-gray-300 placeholder-gray-500 focus:outline-none focus:bg-white focus:border-white focus:ring-secondary-red focus:text-gray-900 text-sm"
                                    placeholder="Procurar por um pedido"
                                    type="text"
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                                    <button onClick={(e) => handleClickClearInputSearch(e)} className="cursor-pointer h-5 w-5 text-primary-blue">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 text-gray-500"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="#021A75"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                    <SearchIcon onClick={(e) => handleOnChangeSearch(e)} className="cursor-pointer h-5 w-5 text-primary-blue" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row-span-1 col-span-2 bg-white rounded-xl mx-auto w-full h-full relative'>
                        {
                            records.map(order => {
                                return (
                                    <div key={order.id} className='row-span-1 col-span-3 bg-white rounded-xl shadow-md mx-auto w-full mb-[20px]'>
                                        <div className='rounded-t-xl h-[40px] w-full flex items-center'>
                                            <h2 className='text-left px-[39px] font-bold' id={`heading-${order.id}`}>
                                                <button className={`accordion-button relative flex items-center w-full py-4 px-5 text-left rounded-none transition focus:outline-none font-bold ${(order.status === "PAID" ? "text-green" : "text-primary-red")} text-[15px] collapsed duration-15`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${order.id}`}
                                                    aria-controls={`collapse${order.id}`}>
                                                    {
                                                        (order.nf.length > 0) ? 'Nota Fiscal Disponível'
                                                            : (order.status === "PAID")
                                                                ? 'Pagamento aprovado'
                                                                : (order.status === "WAITING") ? 'Aguardando pagamento' : 'Pagamento com problemas'
                                                    }
                                                </button>
                                            </h2>
                                        </div>
                                        <hr className='border-[2px] mx-[30px]'></hr>
                                        <aside className='py-[10px] text-left px-[10px] pr-[120px] text-[14px] text-gray-700 flex flex-row items-center justify-between w-full'>
                                            <div className='flex flex-row items-center justify-between pb-[20px]'>
                                                <img className='max-w-[120px]' src={order.product.images[0].urlImg} alt="" />
                                                <div>
                                                    <p className='font-bold px-[25px]'>{order.offer.title}</p>
                                                    {
                                                        (order.nf.length > 0) && <a className="px-[25px] text-[12px] underline text-gray-500 cursor-pointer" target="_blank" href={(order.nf.length > 0) && order.nf[0].fileNf}>Ver nota fiscal</a>
                                                    }
                                                </div>
                                            </div>

                                        </aside>
                                        <div id={`collapse${order.id}`} className="accordion-collapse collapse" aria-labelledby={`heading${order.id}`}
                                            data-bs-parent="#faqsAccordion">
                                            <div className="accordion-body py-4 px-10">
                                                <div className='flex flex-row items-center justify-between'>
                                                    <div className='flex flex-col items-start justify-between pb-[15px]'>
                                                        <p className='font-bold'>Prazo de entrega</p>
                                                        <p className=''>15 dias</p>
                                                    </div>
                                                    <div className='flex flex-col items-start justify-between pb-[15px]'>
                                                        <p className='font-bold'>Quantidade</p>
                                                        <p className=''>{order.cart.quantity}</p>
                                                    </div>
                                                    <div className='flex flex-col items-start justify-between pb-[15px]'>
                                                        <p className='font-bold'>Valor</p>
                                                        <p className=''>{formatMoney(order.cart.price)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='accordion-body py-4 px-20 pb-[60px]'>
                                                <div className="mx-4 p-4">
                                                    <div className="flex items-center">
                                                        <div className="flex items-center text-white relative">
                                                            <div className="flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-green-stepper bg-green-stepper">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 3.75H6.912a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859M12 3v8.25m0 0l-3-3m3 3l3-3" />
                                                                </svg>
                                                            </div>
                                                            <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium text-teal-600">
                                                                <span>Pedido <br></br> recebido</span>
                                                                <p className='pt-[10px]'>{TimestampFormatterCreatedAt(order.created_at)}</p>
                                                            </div>
                                                        </div>
                                                        <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"></div>
                                                        <div className={`${(order.payment_date !== null) ? 'text-white' : 'text-gray-500'} flex items-center relative`}>
                                                            <div className={`${(order.payment_date !== null) && 'bg-green-stepper'} flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-300`}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                                                </svg>
                                                            </div>
                                                            <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium text-teal-600">
                                                                <span>Pagamento <br></br> aprovado</span>
                                                                {(order.payment_date !== null) && (<p className='pt-[10px]'>{TimestampFormatterPaymenteDate(order.payment_date)}</p>)}
                                                            </div>
                                                        </div>
                                                        <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"></div>
                                                        <div className={`${(order.nf.length > 0) ? 'text-white' : 'text-gray-500'} flex items-center relative`}>
                                                            <div className={`${(order.nf.length > 0) && 'bg-green-stepper'} flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-300`}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                                                                </svg>
                                                            </div>
                                                            <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium text-gray-500">
                                                                <span>Nota Fiscal <br></br> disponível</span>
                                                                {(order.nf.length > 0) && (<p className='pt-[10px]'>{TimestampFormatterCreatedAt(order.nf[0].created_at)}</p>)}
                                                            </div>
                                                        </div>
                                                        <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"></div>
                                                        <div className="flex items-center text-gray-500 relative">
                                                            <div className="flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-300">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
                                                                </svg>
                                                            </div>
                                                            <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-gray-500">Em transporte</div>
                                                        </div>
                                                        <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"></div>
                                                        <div className="flex items-center text-gray-500 relative">
                                                            <div className="flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-300">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                                                                </svg>
                                                            </div>
                                                            <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-gray-500">
                                                                Pedido <br></br> entregue
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='accordion-body py-4 px-20 mt-[16px]'>
                                                <div className='flex justify-between'>
                                                    <div className={`bg-primary-blue text-white rounded-xl hover:opacity-80 duration-150 flex flex-row items-center justify-start font-bold w-[300px] text-center py-[19px] hover:cursor-pointer`}>
                                                        <p className='text-center w-full'>
                                                            Devolver
                                                        </p>
                                                    </div>
                                                    <div onClick={() => navigate('/helpcenter')} className={`bg-primary-blue text-white rounded-xl hover:opacity-80 duration-150 flex flex-row items-center justify-start font-bold w-[300px] text-center py-[19px] hover:cursor-pointer`}>
                                                        <p className='text-center w-full'>
                                                            Preciso de ajuda
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>


                                            {
                                                (order.payment_date === null) && (
                                                    <div className='accordion-body py-4 px-20'>
                                                        <div className='flex justify-between'>
                                                            <div onClick={(e) => handleClickFinalizarPagamento(e, order.payment_type, order.id_charge_gateway)} className={`bg-primary-red text-white rounded-xl hover:opacity-80 duration-150 flex flex-row items-center justify-start font-bold w-full text-center py-[19px] hover:cursor-pointer`}>
                                                                <p className='text-center w-full'>
                                                                    Finalizar Pagamento
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            <div className='accordion-body py-4 px-10 mt-[16px]'>
                                                <div className='flex'>
                                                    <div className='row-span-1 bg-white rounded-xl mx-auto w-full'>
                                                        <div className='rounded-t-xl h-[50px] w-full flex items-center'>
                                                            <h2 className='text-primary-red text-left px-[39px] font-bold'>ENDEREÇO DE ENTREGA</h2>
                                                        </div>
                                                        <aside className='pt-[40px] pb-[50px] text-left pl-[30px] pr-[60px] text-[14px] text-gray-700'>
                                                            <p className='font-medium'>
                                                                {`${user.firstName} ${user.lastName}`}
                                                            </p>
                                                            <p>
                                                                {
                                                                    `${user.mainAddress.street}, ${user.mainAddress.number} - ${user.mainAddress.complement}`
                                                                }
                                                            </p>
                                                            <p>
                                                                {user.mainAddress.district}
                                                            </p>
                                                            <p>
                                                                {`${user.mainAddress.city} - ${user.mainAddress.state}`}
                                                            </p>
                                                            <p>
                                                                {`CEP ${user.mainAddress.cep}`}
                                                            </p>
                                                        </aside>
                                                    </div>
                                                    <div className='row-span-1 bg-white rounded-xl mx-auto w-full'>
                                                        <div className='rounded-t-xl h-[50px] w-full flex items-center'>
                                                            <h2 className='text-primary-red text-left px-[39px] font-bold'>FORMA DE PAGAMENTO</h2>
                                                        </div>
                                                        <aside className='flex items-center justify-center w-full h-[217px]'>
                                                            <img className='p-[10px] w-[200px] border-[2px] border-green shadow-md rounded-xl' src={logo_pagseguro} alt="Logo Mercado Pago" />
                                                        </aside>
                                                    </div>
                                                    <div className='row-span-1 bg-white rounded-xl mx-auto w-full'>
                                                        <div className='rounded-t-xl h-[50px] w-full flex items-center'>
                                                            <h2 className='text-primary-red text-left px-[39px] font-bold'>RESUMO DO PEDIDO</h2>
                                                        </div>
                                                        <aside className='py-[30px] text-left text-[14px] text-gray-700 flex items-center justify-between w-full flex flex-col px-[30px]'>
                                                            <div className='flex flex-row items-center justify-between pb-[15px] w-full'>
                                                                <p className=''>Produto</p>
                                                                <p className='font-medium'>{formatMoney(order.cart.price)}</p>
                                                            </div>
                                                            <div className='flex flex-row items-center justify-between pb-[26px] w-full'>
                                                                <p className=''>Frete</p>
                                                                <p className='font-medium'>{formatMoney(deliveryFee)}</p>
                                                            </div>
                                                            <div className='flex flex-row items-start justify-between border-gray-700 border-t-[1px] pt-[28px] w-full'>
                                                                <p className='text-[22px] font-medium'>Total</p>
                                                                <div className='text-right'>
                                                                    <p className='text-[22px] text-green font-medium'>{formatMoney(parseFloat(order.cart.price) + parseFloat(deliveryFee))}</p>
                                                                    <p className='text-[14px] -mt-[8px]'>Em até 10x sem juros</p>
                                                                </div>
                                                            </div>
                                                        </aside>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </article>

                {/* =======@ MOBILE @======= */}
                <article className='bg-white py-[30px] rounded-xl shadow-md mb-[40px] px-[30px] mx-[16px] mb-[40px] lg:hidden'>
                    <div className='flex flex-row items-center hover:cursor-pointer hover:opacity-80' onClick={() => navigate(-1)}>
                        <AiOutlineArrowLeft className='text-primary-blue font-bold' />
                        <p className='px-2 text-primary-blue underline'>Voltar</p>
                    </div>
                    <h3 className="text-primary-blue text-left w-full font-bold text-[35px]">Meus Pedidos</h3>
                    <div className="text-sm flex-auto pb-[30px] pt-[30px]">
                        <div className="w-full">
                            <label htmlFor="search" className="sr-only">
                                Procurar por um pedido
                            </label>
                            <div className="relative">
                                <input
                                    id="search"
                                    name="search"
                                    className="block w-full h-[40px] mx-auto pr-10 pl-3 py-1 border border-gray-300 rounded-lg leading-5 bg-white text-gray-300 placeholder-gray-500 focus:outline-none focus:bg-white focus:border-white focus:ring-secondary-red focus:text-gray-900 text-sm"
                                    placeholder="Procurar por um pedido"
                                    type="search"
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <SearchIcon className="h-5 w-5 text-primary-blue" aria-hidden="true" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row-span-1 col-span-2 bg-white rounded-xl mx-auto w-full h-full relative'>
                        {
                            orders.map(order => {
                                return (
                                    <div key={order.id} className='row-span-1 col-span-3 bg-white rounded-xl shadow-md mx-auto w-full mb-[20px]'>
                                        <div className='rounded-t-xl h-[40px] w-full flex items-center'>
                                            <h2 className='text-left px-[39px] font-bold' id={`heading${order.id}`}>
                                                <button className={`accordion-button relative flex items-center w-full py-4 px-5 text-left rounded-none transition focus:outline-none font-bold ${(order.status === "PAID" ? "text-green" : "text-primary-red")} text-[15px] collapsed duration-15`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${order.id}`}
                                                    aria-controls={`collapse${order.id}`}>
                                                    {
                                                        (order.nf.length > 0) ? 'Nota Fiscal Disponível'
                                                            : (order.status === "PAID")
                                                                ? 'Pagamento aprovado'
                                                                : (order.status === "WAITING") ? 'Aguardando pagamento' : 'Pagamento com problemas'
                                                    }
                                                </button>
                                            </h2>
                                        </div>
                                        <hr className='border-[2px] mx-[30px]'></hr>
                                        <aside className='py-[10px] text-left px-[10px] pr-[120px] text-[14px] text-gray-700 flex flex-row items-center justify-between w-full'>
                                            <div className='flex flex-row items-center justify-between pb-[20px]'>
                                                <img className='max-w-[120px]' src={order.product.images[0].urlImg} alt="" />
                                                <div>
                                                    <p className='font-bold px-[25px]'>{order.offer.title}</p>
                                                    {
                                                        (order.nf.length > 0) && <a className="px-[25px] text-[12px] underline text-gray-500 cursor-pointer" target="_blank" href={(order.nf.length > 0) && order.nf[0].fileNf}>Ver nota fiscal</a>
                                                    }
                                                </div>
                                            </div>
                                        </aside>
                                        <div id={`collapse${order.id}`} className="accordion-collapse collapse" aria-labelledby={`heading${order.id}`} data-bs-parent="#faqsAccordion">
                                            <div className="accordion-body py-4 px-10">
                                                <div className='flex flex-row items-center justify-between'>
                                                    <div className='flex flex-col items-start justify-between pb-[15px]'>
                                                        <p className='font-bold'>Quantidade</p>
                                                        <p className=''>{order.cart.quantity}</p>
                                                    </div>
                                                    <div className='flex flex-col items-start justify-between pb-[15px]'>
                                                        <p className='font-bold'>Valor</p>
                                                        <p className=''>{formatMoney(order.cart.price)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-body py-4 px-10">
                                                <div className='flex flex-row items-center justify-center'>
                                                    <div className='flex flex-col items-start justify-between pb-[15px]'>
                                                        <p className='font-bold'>Prazo de entrega</p>
                                                        <p className=''>15 dias</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='accordion-body pb-[60px]'>
                                                <div className="mx-4 p-4">
                                                    <div className="flex-wrap items-center">
                                                        <div className="flex items-center text-white relative mb-[10px]">
                                                            <div className="flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-green-stepper bg-green-stepper">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 3.75H6.912a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859M12 3v8.25m0 0l-3-3m3 3l3-3" />
                                                                </svg>
                                                            </div>
                                                            <div className="text-center w-32 text-xs font-medium text-teal-600">
                                                                <span>Pedido recebido {TimestampFormatterCreatedAt(order.created_at)}</span>
                                                            </div>
                                                        </div>

                                                        <div className={`${(order.payment_date !== null) ? 'text-white' : 'text-gray-500'} flex items-center relative mb-[10px]`}>
                                                            <div className={`${(order.payment_date !== null) && 'bg-green-stepper'} flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-300`}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                                                </svg>
                                                            </div>
                                                            <div className="text-center w-32 text-xs font-medium text-teal-600">
                                                                <span>
                                                                    Pagamento aprovado {(order.payment_date !== null) && (<>{TimestampFormatterPaymenteDate(order.payment_date)}</>)}
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className={`${(order.nf.length > 0) ? 'text-white' : 'text-gray-500'} flex items-center relative mb-[10px]`}>
                                                            <div className={`${(order.nf.length > 0) && 'bg-green-stepper'} flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-300`}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                                                                </svg>
                                                            </div>
                                                            <div className="text-center w-32 text-xs font-medium text-gray-500">
                                                                <span>Nota Fiscal disponível {(order.nf.length > 0) && (<>{TimestampFormatterCreatedAt(order.nf[0].created_at)}</>)}</span>
                                                            </div>
                                                        </div>

                                                        <div className={`${(order.shipping_date !== null) ? 'text-white' : 'text-gray-500'} flex items-center relative mb-[10px]`}>
                                                            <div className={`${(order.shipping_date !== null) && 'bg-green-stepper'} flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-300`}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
                                                                </svg>
                                                            </div>
                                                            <div className="text-center w-32 text-xs font-medium text-gray-500">
                                                                <span>
                                                                    Em transporte <br></br> {(order.shipping_date !== null) && (<>{TimestampFormatterPaymenteDate(order.shipping_date)}</>)}
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className={`${(order.delivered_date !== null) ? 'text-white' : 'text-gray-500'} flex items-center relative`}>
                                                            <div className={`${(order.delivered_date !== null) && 'bg-green-stepper'} flex justify-center items-center rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-300`}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                                                                </svg>
                                                            </div>
                                                            <div className="text-center w-32 text-xs font-medium text-gray-500">
                                                                Pedido entregue {(order.delivered_date !== null) && (<>{TimestampFormatterPaymenteDate(order.delivered_date)}</>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='accordion-body py-4 px-20 mt-[16px] flex justify-center'>
                                                <div className='flex-column justify-center items-center'>
                                                    <div className={`mb-[10px] bg-primary-blue text-white rounded-xl hover:opacity-80 duration-150 flex flex-row items-center justify-start font-bold w-[300px] text-center py-[19px] hover:cursor-pointer`}>
                                                        <p className='text-center w-full'>
                                                            Devolver
                                                        </p>
                                                    </div>
                                                    <div onClick={() => navigate('/helpcenter')} className={`bg-primary-blue text-white rounded-xl hover:opacity-80 duration-150 flex flex-row items-center justify-start font-bold w-[300px] text-center py-[19px] hover:cursor-pointer`}>
                                                        <p className='text-center w-full'>
                                                            Preciso de ajuda
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                (order.payment_date === null) && (
                                                    <div className='accordion-body py-4 px-20'>
                                                        <div className='flex justify-between'>
                                                            <div onClick={(e) => handleClickFinalizarPagamento(e)} className={`bg-primary-red text-white rounded-xl hover:opacity-80 duration-150 flex flex-row items-center justify-start font-bold w-full text-center py-[19px] hover:cursor-pointer`}>
                                                                <p className='text-center w-full'>
                                                                    Finalizar Pagamento
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            <div className='accordion-body py-4 px-10 mt-[16px]'>
                                                <div className='flex flex-wrap'>
                                                    <div className='row-span-1 bg-white rounded-xl mx-auto w-full'>
                                                        <div className='rounded-t-xl h-[50px] w-full flex items-center'>
                                                            <h2 className='text-primary-red text-left px-[39px] font-bold'>ENDEREÇO DE ENTREGA</h2>
                                                        </div>
                                                        <aside className='pt-[40px] pb-[50px] text-left pl-[30px] pr-[60px] text-[14px] text-gray-700'>
                                                            <p className='font-medium'>
                                                                {`${user.firstName} ${user.lastName}`}
                                                            </p>
                                                            <p>
                                                                {
                                                                    `${user.mainAddress.street}, ${user.mainAddress.number} - ${user.mainAddress.complement}`
                                                                }
                                                            </p>
                                                            <p>
                                                                {user.mainAddress.district}
                                                            </p>
                                                            <p>
                                                                {`${user.mainAddress.city} - ${user.mainAddress.state}`}
                                                            </p>
                                                            <p>
                                                                {`CEP ${user.mainAddress.cep}`}
                                                            </p>
                                                        </aside>
                                                    </div>
                                                    <div className='row-span-1 bg-white rounded-xl mx-auto w-full'>
                                                        <div className='rounded-t-xl h-[50px] w-full flex items-center'>
                                                            <h2 className='text-primary-red text-left px-[39px] font-bold'>FORMA DE PAGAMENTO</h2>
                                                        </div>
                                                        <aside className='flex items-center justify-center w-full h-[217px]'>
                                                            <img className='p-[10px] w-[200px] border-[2px] border-green shadow-md rounded-xl' src={logo_pagseguro} alt="Logo Mercado Pago" />
                                                        </aside>
                                                    </div>
                                                    <div className='row-span-1 bg-white rounded-xl mx-auto w-full'>
                                                        <div className='rounded-t-xl h-[50px] w-full flex items-center'>
                                                            <h2 className='text-primary-red text-left px-[39px] font-bold'>RESUMO DO PEDIDO</h2>
                                                        </div>
                                                        <aside className='py-[30px] text-left text-[14px] text-gray-700 flex items-center justify-between w-full flex flex-col px-[30px]'>
                                                            <div className='flex flex-row items-center justify-between pb-[15px] w-full'>
                                                                <p className=''>Produto</p>
                                                                <p className='font-medium'>{formatMoney(order.cart.price)}</p>
                                                            </div>
                                                            <div className='flex flex-row items-center justify-between pb-[26px] w-full'>
                                                                <p className=''>Frete</p>
                                                                <p className='font-medium'>{formatMoney(deliveryFee)}</p>
                                                            </div>
                                                            <div className='flex flex-row items-start justify-between border-gray-700 border-t-[1px] pt-[28px] w-full'>
                                                                <p className='text-[22px] font-medium'>Total</p>
                                                                <div className='text-right'>
                                                                    <p className='text-[22px] text-green font-medium'>{formatMoney(parseFloat(order.cart.price) + parseFloat(deliveryFee))}</p>
                                                                    <p className='text-[14px] -mt-[8px]'>Em até 10x sem juros</p>
                                                                </div>
                                                            </div>
                                                        </aside>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </article>
            </section >
            <Footer />
        </>
    )
}