import React from 'react';
import { Link } from 'react-router-dom';
// import WhiteLogo from '../assets/logo/WhiteLogo';
import WhiteLogo from '../assets/logo/logo-branco.png';
import FormsDesktop from '../components/FormsDesktop';
import FormsMobile from '../components/FormsMobile';

function CreateAccount () {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <>
    <header className='w-full bg-primary-red h-[68px]'>
      <Link to="/">
        <img src={WhiteLogo} className='h-16 scale-150 hover:opacity-80 duration-150 mx-auto' />
      </Link>
    </header>
    <section className="bg-background-gray w-full text-center pt-[40px]">
      
      <FormsDesktop />
      <FormsMobile />

      <footer className='static text-[12px] text-center w-full mx-auto py-[15px] pt-[40px] text-dark-gray'>
        <hr className='divider2 mb-4 mx-8'/>
        © {year} Mercado Unido. Todos os direitos reservados
      </footer>
    </section>    
    </>
  )
}

export default CreateAccount;